import React, {useState, useEffect} from 'react'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'


import Contestant from './Contestant'

const ContestantHolder = (props) => {
  const [searchedId, setSearchedId] = useState([])
  const [contestants, setContestants] = useState([])
  const [hideEliminated, setHideEliminated] = useState(false)

  const SORT_TYPES = {
    CONTESTANT_NAME: 'contestant',
    BETTER_NAME: 'better',
    ELIMINATION_DATE: 'elimination-order'
  }

  const weeksWatchedStorageKey = 'weeksWatched'

  const [sortRadioValue, setSortRadioValue] = useState(SORT_TYPES.contestant)
  const [weeksWatched, setWeeksWatched] = useState(null)

  const radios = [
    {name: 'Contestant', value: SORT_TYPES.CONTESTANT_NAME},
    {name: 'Better', value: SORT_TYPES.BETTER_NAME},
    {name: 'Elimination Date', value: SORT_TYPES.ELIMINATION_DATE}
  ]

  // This value is used to display the correct number of weeks on the spoiler dropdown
  const mostRecentWeek = Math.max(...props.contestants.map(o => (typeof(o.eliminated) === 'string') ? parseInt(o.eliminated) : o.eliminated))

  useEffect(() => {
    setContestants(props.contestants)
    setWeeksWatched(localStorage.getItem(weeksWatchedStorageKey))
  }, [props.contestants])

  function updateWeeksWatched(weeks) {
    // Convert weeks watched to int since the value from select will be string
    weeks = parseInt(weeks)
    setWeeksWatched(weeks)
    // Send to local storage
    localStorage.setItem(weeksWatchedStorageKey, weeks)
  }

  /**
   * Returns the id for the contestant. Not great practice, but relies on the id for the contestant
   * to be "contestant-<key>"
   *
   * @param {string} value The search value.
   */
  function updateSearchTerm(value) {
    for (var index = 0; index < contestants.length; index++) {
      if (
        contestants[index].contestant_name.toLowerCase().includes(value.toLowerCase()) ||
        contestants[index].better.toLowerCase().includes(value.toLowerCase())
      ) {
        setSearchedId(`contestant-${contestants[index].key}`)
        return;
      }
    }
    setSearchedId(null)
  }

  function handleSearch() {
    if (searchedId) {
      window.location.replace(`#${searchedId}`)
    } else {
      window.location.replace('#')
    }
  }

  function handleSort(sortType, ascending=true) {
    // Updates the currently selected radio value to show which one is active
    setSortRadioValue(sortType)
    const sorted = contestants.sort((item1, item2) => {
      if (sortType === SORT_TYPES.CONTESTANT_NAME) {
        return item1.contestant_name > item2.contestant_name ? 1 : -1
      } else if (sortType === SORT_TYPES.BETTER_NAME) {
        return item1.better > item2.better ? 1 : -1
      } else if (sortType === SORT_TYPES.ELIMINATION_DATE) {
        /* This is to not sort cast members that have been eliminated, but after the current week
        the user is on */
        let treatItem1AsNull = item1.eliminated === null || parseInt(item1.eliminated) > weeksWatched
        let treatItem2AsNull = item1.eliminated === null || parseInt(item1.eliminated) > weeksWatched
        if (treatItem1AsNull && treatItem2AsNull) {
          return 1
        } else if (treatItem1AsNull && !treatItem2AsNull) {
          return 1
        } else if (!treatItem1AsNull && treatItem1AsNull) {
          return -1
        } else {
          return parseInt(item1.eliminated) > parseInt(item2.eliminated) ? 1 : -1
        }
      } else {
        console.log(`unkown sort type ${sortType}, will not sort the data`)
        return 0
      }
    })
    if (!ascending) {
      sorted.reverse()
    }
    setContestants(sorted)
  }

  return (
    <>
      <Row>
        <Col>
          <ButtonToolbar>
            {/* Sorting button group */}
            <ButtonGroup>
              {radios.map((radio, index) => (
                <ToggleButton
                  key={index}
                  id={`radio-${index}`}
                  type="radio"
                  name="radio"
                  value={radio.value}
                  checked={sortRadioValue === radio.value}
                  onChange={(e) => handleSort(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            {/* Spoiler select dropdown */}
            <Form.Select
              aria-label="weeks watched"
              onChange={(e) => updateWeeksWatched(e.currentTarget.value)}
              value={weeksWatched}
            >
              <option value="0">Select weeks watched</option>
              {
                Array.from({length: mostRecentWeek}, (_, weekNum) => {
                  return <option key={weekNum+1} value={weekNum + 1}>Week {weekNum + 1}</option>
                })
              }
            </Form.Select>
            {/* Hide eliminated button */}
            <ToggleButton
              id="toggle-check"
              type="checkbox"
              checked={hideEliminated}
              value="0"
              onChange={(e) => setHideEliminated(e.currentTarget.checked)}
            >
              Hide Eliminated
            </ToggleButton>
            {/* Search Field */}
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Contestant or Better"
                aria-label="Search"
                aria-describedby="searchLabel"
                onChange={(e) => updateSearchTerm(e.currentTarget.value)}
              />
              <Button id="search-button" onClick={handleSearch}>
                Search
              </Button>
            </InputGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        {
          contestants.map((contestant) => {
            if (!hideEliminated || (!contestant.eliminated || parseInt(contestant.eliminated) > weeksWatched)) {
              return (
                <Contestant
                  key={contestant.key}
                  id={`contestant-${contestant.key}`}
                  image_url={contestant.image_url}
                  contestant_name={contestant.contestant_name}
                  better={contestant.better}
                  eliminated={contestant.eliminated}
                  weeksWatched={weeksWatched}
                />
              )
            } else {
              return null
            }
          })
        }
      </Row>
    </>
  )
}

export default ContestantHolder
